import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";
import { getSurveySummary } from "./surveySummaryThunk";

const initialState = {
  getSurveySummaryRes: { status: "", data: null },
};

export const surveySummarySlice = createSlice({
  name: "surveySummary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSurveySummary.pending.toString(), (state) => {
      return {
        ...state,
        getSurveySummaryRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getSurveySummary.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getSurveySummaryRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(getSurveySummary.rejected.toString(), (state) => {
      return {
        ...state,
        getSurveySummaryRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
