const dev = {
  baseURL: "http://localhost:5000",
};

const prod = {
  baseURL: "https://admin.survhana.com",
};

const test = {
  baseURL: "http://localhost:5000",
};

const getEnv = () => {
  switch (process.env.REACT_APP_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
