import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";
import {
  runMatchAlgo,
  getAllEligibleRespondents,
  getRespondentHistoryForSurvey,
} from "./runMatchAlgoThunk";

const initialState = {
  runMatchAlgoRes: { status: "", data: null },
  getAllEligibleRespondentsRes: { status: "", data: null },
  getRespondentHistoryForSurveyRes: { status: "", data: null },
};

export const runMatchAlgoSlice = createSlice({
  name: "runMatchAlgo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(runMatchAlgo.pending.toString(), (state) => {
      return {
        ...state,
        runMatchAlgoRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(runMatchAlgo.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        runMatchAlgoRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(runMatchAlgo.rejected.toString(), (state) => {
      return {
        ...state,
        runMatchAlgoRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getAllEligibleRespondents.pending.toString(), (state) => {
      return {
        ...state,
        getAllEligibleRespondentsRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(
      getAllEligibleRespondents.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getAllEligibleRespondentsRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(getAllEligibleRespondents.rejected.toString(), (state) => {
      return {
        ...state,
        getAllEligibleRespondentsRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(
      getRespondentHistoryForSurvey.pending.toString(),
      (state) => {
        return {
          ...state,
          getRespondentHistoryForSurveyRes: {
            status: APIstatus.IN_PROGRESS,
            data: [],
          },
        };
      }
    );
    builder.addCase(
      getRespondentHistoryForSurvey.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getRespondentHistoryForSurveyRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(
      getRespondentHistoryForSurvey.rejected.toString(),
      (state) => {
        return {
          ...state,
          getRespondentHistoryForSurveyRes: {
            status: APIstatus.FAILURE,
            data: [],
          },
        };
      }
    );
  },
});
