import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const runMatchAlgo = createAsyncThunk(
  "algo/runMatchAlgo",
  async (id, thunkAPI) => {
    const response = await secureApi
      .post("/admin/survey/runmatchingalgo/", {
        surveyId: id,
      })
      .catch((e) => {
        return e;
      });
    if (response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getAllEligibleRespondents = createAsyncThunk(
  "algo/getAllEligibleRespondents",
  async (id, thunkAPI) => {
    const response = await secureApi
      .post("/admin/livesurvey/getAllEligibleRespondents", {
        surveyId: id,
      })
      .catch((e) => {
        return e;
      });
    if (response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getRespondentHistoryForSurvey = createAsyncThunk(
  "algo/getRespondentHistoryForSurvey",
  async (id, thunkAPI) => {
    const response = await secureApi
      .post("/admin/livesurvey/getRespondentHistoryForSurvey", {
        surveyId: id,
      })
      .catch((e) => {
        return e;
      });
    if (response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
