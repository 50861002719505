import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get("/admin/organiztionusers/")
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateUserStatus = createAsyncThunk(
  "organiztion/updateUserStatus",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/admin/organiztionusers/changeStatus`, params)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const changeUserPassword = createAsyncThunk(
  "organiztion/changeUserPassword",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/admin/changeUserPassword`, params)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
