import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getContacts = createAsyncThunk(
  "contacts/getContacts",
  async (thunkAPI) => {
    const response = await secureApi.get("/admin/contactUs/").catch((e) => {
      return e;
    });
    if (response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteContact = createAsyncThunk(
  "contacts/deleteContact",
  async (id, thunkAPI) => {
    const response = await secureApi
      .delete(`/admin/contactUs/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.status) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
