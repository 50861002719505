import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";
import {
  getOnboardingQuestions,
  addOnBoardingQuestion,
  editOnBoardingQuestion,
  deleteOnBoardingQuestion,
  getSingleQuestionData,
  askOnbordingQuestionaAgain,
  getOnboardingAnswers,
  updateOnboardingQuestionsOrder,
} from "./onboardingQuestionThunk";

const initialState = {
  getOnBoardingQuestionsRes: { status: "", data: null },
  addOnBoardingQuestionRes: { status: "", data: null },
  deleteOnBoardingQuestionRes: { status: "", data: null },
  editOnBoardingQuestionRes: { status: "", data: null },
  getSingleQuestionDataRes: { status: "", data: null },
  askOnbordingQuestionaAgainRes: { status: "", data: null },
  getOnboardingAnswersRes: { status: "", data: null },
  updateOnboardingQuestionsOrderRes: { status: "", data: null },
};

export const onboardingQuestionsSlice = createSlice({
  name: "OnboardingQuestions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOnboardingQuestions.pending.toString(), (state) => {
      return {
        ...state,
        getOnBoardingQuestionsRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      getOnboardingQuestions.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getOnBoardingQuestionsRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(getOnboardingQuestions.rejected.toString(), (state) => {
      return {
        ...state,
        getOnBoardingQuestionsRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getSingleQuestionData.pending.toString(), (state) => {
      return {
        ...state,
        getSingleQuestionDataRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      getSingleQuestionData.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getSingleQuestionDataRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(getSingleQuestionData.rejected.toString(), (state) => {
      return {
        ...state,
        getSingleQuestionDataRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(addOnBoardingQuestion.pending.toString(), (state) => {
      return {
        ...state,
        addOnBoardingQuestionRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      addOnBoardingQuestion.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          addOnBoardingQuestionRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(addOnBoardingQuestion.rejected.toString(), (state) => {
      return {
        ...state,
        addOnBoardingQuestionRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(editOnBoardingQuestion.pending.toString(), (state) => {
      return {
        ...state,
        editOnBoardingQuestionRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      editOnBoardingQuestion.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          editOnBoardingQuestionRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(editOnBoardingQuestion.rejected.toString(), (state) => {
      return {
        ...state,
        editOnBoardingQuestionRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(deleteOnBoardingQuestion.pending.toString(), (state) => {
      return {
        ...state,
        deleteOnBoardingQuestionRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(
      deleteOnBoardingQuestion.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          deleteOnBoardingQuestionRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(deleteOnBoardingQuestion.rejected.toString(), (state) => {
      return {
        ...state,
        deleteOnBoardingQuestionRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(askOnbordingQuestionaAgain.pending.toString(), (state) => {
      return {
        ...state,
        askOnbordingQuestionaAgainRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(
      askOnbordingQuestionaAgain.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          askOnbordingQuestionaAgainRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(askOnbordingQuestionaAgain.rejected.toString(), (state) => {
      return {
        ...state,
        askOnbordingQuestionaAgainRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getOnboardingAnswers.pending.toString(), (state) => {
      return {
        ...state,
        getOnboardingAnswersRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      getOnboardingAnswers.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getOnboardingAnswersRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(getOnboardingAnswers.rejected.toString(), (state) => {
      return {
        ...state,
        getOnboardingAnswersRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(
      updateOnboardingQuestionsOrder.pending.toString(),
      (state) => {
        return {
          ...state,
          updateOnboardingQuestionsOrderRes: {
            status: APIstatus.IN_PROGRESS,
            data: [],
          },
        };
      }
    );
    builder.addCase(
      updateOnboardingQuestionsOrder.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          updateOnboardingQuestionsOrderRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(
      updateOnboardingQuestionsOrder.rejected.toString(),
      (state) => {
        return {
          ...state,
          updateOnboardingQuestionsOrderRes: {
            status: APIstatus.FAILURE,
            data: [],
          },
        };
      }
    );
  },
});
