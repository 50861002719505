import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getSurveyFeedback = createAsyncThunk(
  "surveyFeedback/getSurveyFeedback",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/admin/feedback/getall/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
