import {
  DashboardOutlined,
  ApartmentOutlined,
  UserOutlined,
  QuestionOutlined,
  ContactsOutlined,
  GlobalOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "../configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "home",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "organization",
    path: `${APP_PREFIX_PATH}/organization`,
    title: "Organization",
    icon: ApartmentOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "liveSurvey",
    path: `${APP_PREFIX_PATH}/live-survey`,
    title: "Live Survey",
    icon: UnorderedListOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "users",
    path: `${APP_PREFIX_PATH}/users`,
    title: "Users",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "respondents",
    path: `${APP_PREFIX_PATH}/respondents`,
    title: "Respondents",
    icon: UsergroupAddOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "onboardingQuestions",
    path: `${APP_PREFIX_PATH}/onboardingQuestions`,
    title: "On-Boarding Questions",
    icon: QuestionOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "criteriaQuestions",
    path: `${APP_PREFIX_PATH}/criteriaQuestions`,
    title: "Criteria Questions",
    icon: QuestionOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "mapping",
    path: `${APP_PREFIX_PATH}/mapping`,
    title: "Mapping",
    icon: SwapOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "blockedDomains",
    path: `${APP_PREFIX_PATH}/blockedDomains`,
    title: "Blocked Domains",
    icon: GlobalOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "contacts",
    path: `${APP_PREFIX_PATH}/contacts`,
    title: "Contacts",
    icon: ContactsOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
