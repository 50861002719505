import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";
import { getLiveSurvey } from "./liveSurveyThunk";

const initialState = {
  getLiveSurveyRes: { status: "", data: null },
};

export const liveSurveySlice = createSlice({
  name: "liveSurvey",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLiveSurvey.pending.toString(), (state) => {
      return {
        ...state,
        getLiveSurveyRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getLiveSurvey.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getLiveSurveyRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(getLiveSurvey.rejected.toString(), (state) => {
      return {
        ...state,
        getLiveSurveyRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
