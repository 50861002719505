import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getOrganization = createAsyncThunk(
  "organization/getOrganization",
  async (_, thunkAPI) => {
    try {
      const response = await secureApi.get("/admin/organization");
      if (response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue("No data received");
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response?.data || e.message);
    }
  }
);

export const updateOrganizationStatus = createAsyncThunk(
  "organization/updateOrganizationStatus",
  async (params, thunkAPI) => {
    try {
      const { organizationID, status } = params;
      const response = await secureApi.post(
        `/admin/organization/changeStatus`,
        {
          organizationID,
          status,
        }
      );

      if (response) {
        return response;
      } else {
        return thunkAPI.rejectWithValue("No data received");
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response || e.message);
    }
  }
);

export const getOrganiztionUsers = createAsyncThunk(
  "organiztion/getOrganiztionUsers",
  async ({ id }, thunkAPI) => {
    const response = await secureApi
      .get(`/admin/organiztionusers/getUserByOrg/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getSurveyByOrganiztionId = createAsyncThunk(
  "organiztion/getSurveyByOrganiztionId",
  async ({ id }, thunkAPI) => {
    const response = await secureApi
      .post(`/admin/survey/byorganization/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getSurveyByUserId = createAsyncThunk(
  "organiztion/getSurveyByUserId",
  async ({ id }, thunkAPI) => {
    const response = await secureApi
      .post(`/admin/survey/byuser/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateOrganizationSurveyStatus = createAsyncThunk(
  "organization/updateOrganizationSurveyStatus",
  async (params, thunkAPI) => {
    try {
      const { surveyId, status } = params;
      const response = await secureApi.post(
        `/admin/survey/changesurveystatus`,
        {
          surveyId,
          status,
        }
      );

      if (response) {
        return response;
      } else {
        return thunkAPI.rejectWithValue("No data received");
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response || e.message);
    }
  }
);

export const getSurveyDataById = createAsyncThunk(
  "organiztion/getSurveyDataById",
  async (id, thunkAPI) => {
    const response = await secureApi.get(`/admin/survey/${id}`).catch((e) => {
      return e;
    });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
