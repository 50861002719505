import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getLiveSurvey = createAsyncThunk(
  "survey/getLiveSurvey",
  async (thunkAPI) => {
    const response = await secureApi
      .get("/admin/livesurvey/getall")
      .catch((e) => {
        return e;
      });
    if (response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
