import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";
import { getContacts, deleteContact } from "./contactsThunk";

const initialState = {
  getContactsRes: { status: "", data: null },
  deleteContactRes: { status: "", data: null },
};

export const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getContacts.pending.toString(), (state) => {
      return {
        ...state,
        getContactsRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getContacts.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getContactsRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(getContacts.rejected.toString(), (state) => {
      return {
        ...state,
        getContactsRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(deleteContact.pending.toString(), (state) => {
      return {
        ...state,
        deleteContactRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(deleteContact.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        deleteContactRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(deleteContact.rejected.toString(), (state) => {
      return {
        ...state,
        deleteContactRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
