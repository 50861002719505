import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getBlockedDomains = createAsyncThunk(
  "blockedDomains/getBlockedDomains",
  async (params, thunkAPI) => {
    const response = await secureApi.get(`/admin/blockDomain`).catch((e) => {
      return e;
    });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const addBlockedDomain = createAsyncThunk(
  "blockedDomains/addBlockedDomain",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/admin/blockDomain`, params)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateBlockedDomain = createAsyncThunk(
  "blockedDomains/updateBlockedDomain",
  async (params, thunkAPI) => {
    const response = await secureApi
      .patch(`/admin/blockDomain/${params.id}`, { domain: params.domain })
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteBlockedDomain = createAsyncThunk(
  "blockedDomains/deleteBlockedDomain",
  async (id, thunkAPI) => {
    const response = await secureApi
      .delete(`/admin/blockDomain/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
