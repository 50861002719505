import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";
import {
  addBlockedDomain,
  getBlockedDomains,
  updateBlockedDomain,
  deleteBlockedDomain,
} from "./blockedDomainsThunk";

const initialState = {
  addBlockedDomainRes: { status: "", data: null },
  getBlockedDomainsRes: { status: "", data: null },
  updateBlockedDomainRes: { status: "", data: null },
  deleteBlockedDomainRes: { status: "", data: null },
};

export const blockedDomainsSlice = createSlice({
  name: "blockedDomains",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBlockedDomains.pending.toString(), (state) => {
      return {
        ...state,
        getBlockedDomainsRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getBlockedDomains.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getBlockedDomainsRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(getBlockedDomains.rejected.toString(), (state) => {
      return {
        ...state,
        getBlockedDomainsRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(addBlockedDomain.pending.toString(), (state) => {
      return {
        ...state,
        addBlockedDomainRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(addBlockedDomain.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        addBlockedDomainRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(addBlockedDomain.rejected.toString(), (state) => {
      return {
        ...state,
        addBlockedDomainRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(updateBlockedDomain.pending.toString(), (state) => {
      return {
        ...state,
        updateBlockedDomainRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      updateBlockedDomain.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          updateBlockedDomainRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(updateBlockedDomain.rejected.toString(), (state) => {
      return {
        ...state,
        updateBlockedDomainRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
    builder.addCase(deleteBlockedDomain.pending.toString(), (state) => {
      return {
        ...state,
        deleteBlockedDomainRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      deleteBlockedDomain.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          deleteBlockedDomainRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(deleteBlockedDomain.rejected.toString(), (state) => {
      return {
        ...state,
        deleteBlockedDomainRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
