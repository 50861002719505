import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";
import { notification } from "antd";
import tokenServiceInstance from "../../../auth/tokenService";

export const login = createAsyncThunk(
  "auth/login",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi.post("/admin/login", params);
      if (response.data.status) {
        const { token } = response.data;
        tokenServiceInstance.saveToken(token);
        notification.success({ message: response.data.message });
      } else {
        notification.error({ message: response.data.message });
        return thunkAPI.rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        notification.error({ message: error.response.message });
      } else {
        notification.error({ message: error.message });
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi.post("/admin/signup", params);
      if (response.data.status) {
        notification.success({ message: response.data.message });
      } else {
        notification.error({ message: response.data.message });
        return thunkAPI.rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        notification.error({ message: error.response.message });
      } else {
        notification.error({ message: error.message });
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const changeAdminPassword = createAsyncThunk(
  "auth/changeAdminPassword",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/admin/changePassword", params, {
        "public-requrest": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.status) {
      notification.success({ message: response.data.message });
    } else {
      notification.error(response.data.message);
    }
  }
);
