import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getMapping = createAsyncThunk(
  "mapping/getMapping",
  async (thunkAPI) => {
    const response = await secureApi.get("/admin/mapping/").catch((e) => {
      return e;
    });
    if (response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getSingleMapping = createAsyncThunk(
  "mapping/getSingleMapping",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/admin/onBoardingQuestions/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const addMapping = createAsyncThunk(
  "mapping/addMapping",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/admin/mapping/", params)
      .catch((e) => {
        return e;
      });
    if (response.status) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const editMapping = createAsyncThunk(
  "mapping/editMapping",
  async (params, thunkAPI) => {
    const response = await secureApi
      .patch(`/admin/mapping/${params.id}`, params)
      .catch((e) => {
        return e;
      });
    if (response.status) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteMapping = createAsyncThunk(
  "mapping/deleteMapping",
  async (id, thunkAPI) => {
    const response = await secureApi
      .delete(`/admin/mapping/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.status) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
