import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";
import { getUsers, updateUserStatus, changeUserPassword } from "./userThunk";

const initialState = {
  usersListRes: { status: "", data: null },
  updateUserStatusRes: { status: "", data: null },
  changeUserPasswordData: { status: "", data: {} },
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending.toString(), (state) => {
      return {
        ...state,
        usersListRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getUsers.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        usersListRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(getUsers.rejected.toString(), (state) => {
      return {
        ...state,
        usersListRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(updateUserStatus.pending.toString(), (state) => {
      return {
        ...state,
        updateUserStatusRes: { status: APIstatus.IN_PROGRESS },
      };
    });
    builder.addCase(updateUserStatus.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        updateUserStatusRes: {
          status: APIstatus.SUCCESS,
        },
      };
    });
    builder.addCase(updateUserStatus.rejected.toString(), (state) => {
      return {
        ...state,
        updateUserStatusRes: { status: APIstatus.FAILURE },
      };
    });

    builder.addCase(changeUserPassword.pending.toString(), (state) => {
      return {
        ...state,
        changeUserPasswordData: { status: APIstatus.IN_PROGRESS },
      };
    });
    builder.addCase(
      changeUserPassword.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          changeUserPasswordData: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(changeUserPassword.rejected.toString(), (state) => {
      return {
        ...state,
        changeUserPasswordData: { status: APIstatus.FAILURE },
      };
    });
  },
});
