import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";
import { getSurveyFeedback } from "./feedbackThunk";

const initialState = {
  getSurveyFeedbackRes: { status: "", data: null },
};

export const surveyFeedbackSlice = createSlice({
  name: "surveyFeedback",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSurveyFeedback.pending.toString(), (state) => {
      return {
        ...state,
        getSurveyFeedbackRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getSurveyFeedback.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getSurveyFeedbackRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(getSurveyFeedback.rejected.toString(), (state) => {
      return {
        ...state,
        getSurveyFeedbackRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
