import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "../layouts/app-layout";
import AuthLayout from "../layouts/auth-layout";
import AppLocale from "../lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "../configs/AppConfig";
import useBodyClass from "../hooks/useBodyClass";

export const Views = (props) => {
  const { locale, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);

  const authToken = localStorage.getItem("authToken");

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect
              to={authToken ? APP_PREFIX_PATH : `${AUTH_PREFIX_PATH}/login`}
            />
          </Route>
          <Route path={`${AUTH_PREFIX_PATH}/login`}>
            {authToken ? (
              <Redirect to={APP_PREFIX_PATH} />
            ) : (
              <AuthLayout direction={direction} />
            )}
          </Route>
          <Route path={APP_PREFIX_PATH}>
            {authToken ? (
              <AppLayout direction={direction} location={location} />
            ) : (
              <Redirect to={`${AUTH_PREFIX_PATH}/login`} />
            )}
          </Route>
          <Route>
            <Redirect
              to={authToken ? APP_PREFIX_PATH : `${AUTH_PREFIX_PATH}/login`}
            />
          </Route>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token, loginRes } = auth;
  return { locale, token, direction, loginRes };
};

export default withRouter(connect(mapStateToProps)(Views));
